import React from 'react'

function Memastic() {
  return (
    <div className='flex items-center justify-center border-2 border-[#FFEE55] mt-10'>
         <p className='text-center text-4xl lg:text-6xl lg:w-[800px]  py-7 z-10'>
         The most memetastic coin in crypto, because who needs boring finance?
       </p>
       
    </div>
  )
}

export default Memastic